import React, { Component } from "react";
import { Link } from "react-router-dom";

import { AppLinks } from "../../../constants/app-links";
import ReferFriend from "../../common/refer-friend/ReferFriend";
import StatusHeader from "../../common/status-header/StatusHeader";
import Util, { capitalizeFirstLetter, getProtectedSinceDate } from "../../common/util/util";
import "./ReviewPolicy.component.css";

class ReviewPolicy extends Component {

    render() {
        const { petDetails, petAge, selectedPlan, existingPets, policyDetails, newPetDetails, breedsData, physicalAddress, policyNumber, vetExamValue, userAccountDetails } = this.props;
        const breed = breedsData.find(breed => breed.id === Number(petDetails.breed));
        let petNames = [];
        existingPets.map(pet => petNames.push(capitalizeFirstLetter(pet.Name)));
        petNames.push(capitalizeFirstLetter(petDetails.petName));

        let isMobileAppView = false;
        if (window.location.pathname.toLowerCase() === "/mobileappaddpet") {
            isMobileAppView = true;
        }

        //if not mobile view show normal view 
        if (!isMobileAppView) {
            return (
                <div>
                    <StatusHeader className="status">
                        <div className="d-inline-flex align-items-center status">
                            <i className="fa fa-check-circle align-middle" />
                            <div className="status-info">Success!</div>
                            <div className="d-none d-md-block status-text">
                                &nbsp;<span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span> has been successfully added to your policy
                            </div>
                        </div>
                    </StatusHeader>
                    <div className="container review-policy">
                        <h1 className="text-primary mb-3 mb-lg-0">Review <span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span>'s new policy</h1>
                        <div className="row">
                            <div className="col-lg-8">
                                <p>Premium may not be accurate if you recently added a pet or changed your address. It may take up to a day for the premium to update on the site</p>
                                <p>We are looking forward to helping you keep {Util.getCommaSeperatedNameString(petNames.map(petName => petName))} happy and healthy for years to come. To view your policy documents, please visit the <Link to={AppLinks.policy.viewPolicyDocuments} >policy documents</Link> page.</p>
                                <p><span className="font-weight-bold">Send us <span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span>'s medical records.</span> If you want to upload the medical records or other documentation for <span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span>, please <Link to={AppLinks.claims.uploadMedicalRecords} >click here</Link>.</p>
                                {vetExamValue === 'no' && (
                                    <div className="veterinary-exam">
                                        <p className="font-weight-bold mb-0">Don't forget to fulfill veterinary exam requirements:</p>
                                        <p className="mb-0"><span className="truncate" style={{ textTransform: "capitalize" }}>{petDetails.petName}</span> { this.props.userAccountDetails.ModelLawInEffect == true ? "should" : "must" } have a veterinary exam within the next 15 days.</p>
                                    </div>
                                )}
                                <h2 className="mb-4 font-weight-bold">Your new pet</h2>
                                <div className="new-pet">
                                    <div className="d-flex flex-wrap m-0 mb-2 align-items-md-baseline">
                                        <div className="pet-name truncate" style={{ textTransform: "capitalize" }}>{petDetails.petName}</div>
                                        <div className="order-md-2 align-self-center"><label className="tag">New</label></div>
                                        <div className="w-100 d-md-none"></div>
                                        <div className="order-md-1 ml-md-2">{petAge === 0 ? petDetails.petType === "dog" ? "Puppy" : "Kitten" : "Age " + petAge}, {breed.name}</div>
                                    </div>
                                    <div className="row table-view">
                                        <div className="col-6">
                                            <span className="title">Pet ID:</span>
                                            <p className="content">{newPetDetails.pet_policy_number}</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Policy Number:</span>
                                            <p className="content">{policyNumber}</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Policy Effective Date:</span>
                                            <p className="content">{policyDetails.policy_effective_date}</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Monthly Premium:</span>
                                            <p className="content">${selectedPlan.monthly_premium}</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Reimbursement</span>
                                            <p className="content">{selectedPlan.reimbursement * 100}%</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Annual Deductible:</span>
                                            <p className="content">${selectedPlan.deductible}</p>
                                        </div>
                                    </div>
                                    {/* <table className="col-md-6 table table-md table-borderless">
                                        <tbody>
                                            <tr>
                                                <th className="pl-0">Pet ID:</th>
                                                <td className="text-right">{newPetDetails.pet_policy_number}</td>
                                                <th className="pl-0">Policy Number:</th>
                                                <td className="text-right">{policyNumber}</td>
                                            </tr>
                                            
                                            <tr>
                                                <th className="pl-0">Policy Effective Date:</th>
                                                <td className="text-right">{policyDetails.policy_effective_date}</td>
                                                <th className="pl-0">Monthly Premium:</th>
                                                <td className="text-right">${selectedPlan.monthly_premium}</td>
                                            </tr>
                                            
                                            <tr>
                                                <th className="pl-0">Reimbursement:</th>
                                                <td className="text-right">{selectedPlan.reimbursement * 100}%</td>
                                                <th className="pl-0">Annual Deductible:</th>
                                                <td className="text-right">${selectedPlan.deductible}</td>
                                            </tr>
                                            
                                        </tbody>
                                    </table> */}
                                    <div className="d-flex flex-row text-blue">
                                        <span className="mr-1 order-lg-1 order-xl-0 font-weight-bold" style={{ textTransform: "capitalize" }} ><span className="truncate">{petDetails.petName}</span>'s Waiting Period </span>
                                        <i className="mr-1 order-lg-0 order-xl-1 d-lg-block align-self-center fa fa-question-circle" />
                                    </div>
                                    { (this.props.physicalAddress.State ==="NH") ? (
                                        <ul>
                                            <li>You can submit claims for accidents and illnesses that occur after { policyDetails.policy_effective_date }</li>
                                        </ul>)  :   (
                                        <ul>
                                            {/* Logic for Non - Model Law states 2024*/}
                                            {!this.props.userAccountDetails.ModelLawInEffect && <li>You can submit claims for accidents, illnesses, or injuries that occur after {getProtectedSinceDate(policyDetails.policy_effective_date)}.</li>}
                                            {!this.props.userAccountDetails.ModelLawInEffect && petAge < 6 && <li>There is a 12-month waiting period for hip dysplasia for pets younger than six years.</li>}
                                            {/* Logic for Model Law states 2024*/}
                                            {this.props.userAccountDetails.ModelLawInEffect && <li>You can submit claims for illnesses{this.props.physicalAddress.State === "WA" ? "" : " or injuries"} that occur after {getProtectedSinceDate(policyDetails.policy_effective_date)}.</li>}
                                            {this.props.userAccountDetails.ModelLawInEffect && petAge < 6 && <li>There is a 30-day waiting period for hip dysplasia not resulting from an accident for pets younger than six years.</li>}
                                            {this.props.userAccountDetails.ModelLawInEffect && <li>However, waiting periods will not apply if waived by us upon completion of a complete clinical examination.</li>}
                                        </ul>)
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <button
                                            className="button-primary add-another-pet-btn"
                                            type="button"
                                            style={{ cursor: "pointer" }}
                                            onClick={
                                                () => {
                                                    this.props.reset();
                                                    this.props.stepper.goToFirst();
                                                }
                                            }
                                        >Add Another Pet</button>
                                    </div>
                                    <div className="col-md-5">
                                        <Link to="/" >
                                            <button
                                                className="button-secondary button secondar-button"
                                                type="button"
                                                style={{ cursor: "pointer" }}
                                            >Home
                                            </button>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-lg-4 d-none d-lg-block align-items-start">
                            <ReferFriend />
                        </div> */}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <StatusHeader className="status">
                        <div className="d-inline-flex align-items-center status">
                            <i className="fa fa-check-circle align-middle" />
                            <div className="status-info">Success!</div>
                            <div className="d-none d-md-block status-text">
                                &nbsp;<span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span> has been successfully added to your policy
                            </div>
                        </div>
                    </StatusHeader>
                    <div className="container review-policy">
                        <h1 className="text-primary mb-3 mb-lg-0">Review <span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span>'s new policy</h1>
                        <div className="row">
                            <div className="col-lg-8">
                                <p>Premium may not be accurate if you recently added a pet or changed your address. It may take up to a day for the premium to update on the site</p>
                                <p>We are looking forward to helping you keep {Util.getCommaSeperatedNameString(petNames.map(petName => petName))} happy and healthy for years to come. To view your policy documents, please visit the <Link to={AppLinks.policy.viewPolicyDocuments} >policy documents</Link> page.</p>
                                <p><span className="font-weight-bold">Send us <span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span>'s medical records.</span> If you want to upload the medical records or other documentation for <span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span>, please <Link to={AppLinks.claims.uploadMedicalRecords} >click here</Link>.</p>
                                {vetExamValue === 'no' && (
                                    <div className="veterinary-exam">
                                    <p className="font-weight-bold mb-0">Don't forget to fulfill veterinary exam requirements:</p>
                                    <p className="mb-0"><span className="truncate" style={{ textTransform: "capitalize" }} >{petDetails.petName}</span> { this.props.userAccountDetails.ModelLawInEffect == true ? "should" : "must" } have a veterinary exam within the next 15 days.</p>
                                </div>
                                )}
                                <h2 className="mb-4 font-weight-bold">Your new pet</h2>
                                <div className="new-pet">
                                    <div className="d-flex flex-wrap m-0 mb-2 align-items-md-baseline">
                                        <div className="pet-name truncate" style={{ textTransform: "capitalize" }}>{petDetails.petName}</div>
                                        <div className="order-md-2 align-self-center"><label className="tag">New</label></div>
                                        <div className="w-100 d-md-none"></div>
                                        <div className="order-md-1 ml-md-2">{petAge === 0 ? petDetails.petType === "dog" ? "Puppy" : "Kitten" : "Age " + petAge}, {breed.name}</div>
                                    </div>
                                    <div className="row table-view">
                                        <div className="col-6">
                                            <span className="title">Pet ID:</span>
                                            <p className="content">{newPetDetails.pet_policy_number}</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Policy Number:</span>
                                            <p className="content">{policyNumber}</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Policy Effective Date:</span>
                                            <p className="content">{policyDetails.policy_effective_date}</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Monthly Premium:</span>
                                            <p className="content">${selectedPlan.monthly_premium}</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Reimbursement</span>
                                            <p className="content">{selectedPlan.reimbursement * 100}%</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="title">Annual Deductible:</span>
                                            <p className="content">${selectedPlan.deductible}</p>
                                        </div>
                                    </div>
                                    {/* <table className="col-md-6 table table-md table-borderless">
                                        <tbody>
                                            <tr>
                                                <th className="pl-0">Pet ID:</th>
                                                <td className="text-right">{newPetDetails.pet_policy_number}</td>
                                            </tr>
                                            <tr>
                                                <th className="pl-0">Policy Number:</th>
                                                <td className="text-right">{policyNumber}</td>
                                            </tr>
                                            <tr>
                                                <th className="pl-0">Policy Effective Date:</th>
                                                <td className="text-right">{policyDetails.policy_effective_date}</td>
                                            </tr>
                                            <tr>
                                                <th className="pl-0">Monthly Premium:</th>
                                                <td className="text-right">${selectedPlan.monthly_premium}</td>
                                            </tr>
                                            <tr>
                                                <th className="pl-0">Reimbursement:</th>
                                                <td className="text-right">{selectedPlan.reimbursement * 100}%</td>
                                            </tr>
                                            <tr>
                                                <th className="pl-0">Annual Deductible:</th>
                                                <td className="text-right">${selectedPlan.deductible}</td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    <div className="d-flex flex-row text-secondary">
                                        <span className="mr-1 order-lg-1 order-xl-0 font-weight-bold" style={{ textTransform: "capitalize" }} ><span className="truncate">{petDetails.petName}</span>'s Waiting Period </span>
                                        <i className="mr-1 order-lg-0 order-xl-1 d-lg-block align-self-center fa fa-question-circle" />
                                    </div>
                                    { (this.props.physicalAddress.State ==="NH") ? (
                                        <ul>
                                            <li>You can submit claims for accidents and illnesses that occur after { policyDetails.policy_effective_date }</li>
                                        </ul>)  :   (
                                        <ul>
                                            {/* Logic for Non - Model Law states 2024*/}
                                            {!this.props.userAccountDetails.ModelLawInEffect && <li>You can submit claims for accidents, illnesses, or injuries that occur after {getProtectedSinceDate(policyDetails.policy_effective_date)}.</li>}
                                            {!this.props.userAccountDetails.ModelLawInEffect && petAge < 6 && <li>There is a 12-month waiting period for hip dysplasia for pets younger than six years.</li>}
                                            {/* Logic for Model Law states 2024*/}
                                            {this.props.userAccountDetails.ModelLawInEffect && <li>You can submit claims for illnesses{this.props.physicalAddress.State === "WA" ? "" : " or injuries"} that occur after {getProtectedSinceDate(policyDetails.policy_effective_date)}.</li>}
                                            {this.props.userAccountDetails.ModelLawInEffect && petAge < 6 && <li>There is a 30-day waiting period for hip dysplasia not resulting from an accident for pets younger than six years.</li>}
                                            {this.props.userAccountDetails.ModelLawInEffect && <li>However, waiting periods will not apply if waived by us upon completion of a complete clinical examination.</li>}
                                        </ul>)
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <button
                                            className="button-primary add-another-pet-btn"
                                            type="button"
                                            style={{ cursor: "pointer" }}
                                            onClick={
                                                () => {
                                                    this.props.reset();
                                                    this.props.stepper.goToFirst();
                                                }
                                            }
                                        >Add Another Pet</button>
                                    </div>
                                   
                                </div>

                            </div>
                            {/* <div className="col-lg-4 d-none d-lg-block align-items-start">
                            <ReferFriend />
                        </div> */}
                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default ReviewPolicy;