import React from "react";
import { FAQLinks } from "../../../constants/app-links";

export default class petEligible {
  static vetExam = (policyFormUrl, stateAbbreviation) => {

    /*
    var NineStates = ['GA', 'HI', 'ME', 'MN', 'MO', 'NH', 'OR', 'RI', 'TX'];
    var ClaimEndorsment = () =>{
      if(NineStates.find(element => element === userAddressState) !== undefined) {
        return(<a href={FAQLinks.PIM_52785} target="_blank" rel="noopener noreferrer" > Claims Process Endorsement </a> );
      }else if(userAddressState === "IL"){
        return(<a href={FAQLinks.PIM_52784} target="_blank" rel="noopener noreferrer" > Claims Process Endorsement </a> );
      }else if(userAddressState === "VA"){
        return(<a href={FAQLinks.PIM_52906} target="_blank" rel="noopener noreferrer" > Claims Process Endorsement </a> );
      }else if(userAddressState === "WA"){
        return(<a href={FAQLinks.PIM_52986} target="_blank" rel="noopener noreferrer" > Claims Process Endorsement </a> );
      }else if(userAddressState === "MA"){
        return(<a href={FAQLinks.PIM_52785_PIM_53538} target="_blank" rel="noopener noreferrer" > Claims Process Endorsement </a> );
      }else {
        return(<a href={FAQLinks.PIM_52552} target="_blank" rel="noopener noreferrer" > Claims Process Endorsement </a> );
      }        
    }
    */
    
    var content = '';
    var policyFormLink = '';
    var faqPickerUrl = FAQLinks.FAQPicker;
    var mainSiteUrl = process.env.REACT_APP_MAINSITE_URL;

    if (policyFormUrl) {
      policyFormLink = `<a style="font-weight:600" href="` + policyFormUrl + `" target="_blank" rel="noopener noreferrer">
          To review your policy, please click here.
        </a>`
    } else {
      policyFormLink = `<a style="font-weight:600" href="` + faqPickerUrl + `" target="_blank" rel="noopener noreferrer">
          To review your policy, please click here.
        </a>`
    }  

    switch (stateAbbreviation) {
      case "DE":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "LA":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "ME":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "MS":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "NE":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "NH":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "OH":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "WA":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "PA":
          content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
            <p>${policyFormLink}</p>`;
          break;
      case "MD":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      case "CA":
        content = `<p>A clinical examination is not required, but should be secured to ensure the ease and speed of processing your claim(s).  You may upload a copy of your pet's most recent exam records in the Customer Center.</p>
          <p>${policyFormLink}</p>`;
        break;
      default:
            content = `<p>The Healthy Paws pet insurance plan requires a full nose-to-tail physical exam of your pet by a licensed veterinarian in order to be eligible for coverage.</p>
          <p>No additional diagnostic testing such as x-rays or blood work is required. The timing of this exam will depend on your pet's age at the time of enrollment as follows:
          </p>
          <div style="float:left;width:100%;">
            <div style="float:left;width:282px;margin-right:10px;margin-bottom:10px">
              <img
                alt="faq images"
                style="width:282px;height:127px;"
                src="${mainSiteUrl}/ContentSourceFiles/FAQ/faq-images/Under5_M@2x.png" }
              />
            </div>
            <div style="float:left;width:282px;margin-bottom:10px">
              <img
                alt="faq images"
                style="width:282px;height:127px;"
                src="${mainSiteUrl}/ContentSourceFiles/FAQ/faq-images/Over6_M@2x.png" }
              />
            </div>
            <div style="clear: both;"></div>
          </div>  
          <p>If you recently adopted your pet, you may need to double-check that they have had a clinical examination by a licensed veterinarian. Some adoption organizations will have a licensed veterinarian on staff, whereas some don't. Please ensure your pet is seen by a licensed veterinarian either prior to enrollment, or during the first 15 days of your policy.</p> 
          <p>When you file your first claim, we will need a copy of your pet's full medical history, including documentation of the required clinical exam.</p>
      
          <p>${policyFormLink}</p>`;
        break;
    }

    return (
      <div className="faqAnswer" dangerouslySetInnerHTML={{ __html: content }}></div>
    );
  };

  
  static petExam = (userAddressState) => {
    return (
      <div className="faqAnswer">
        <p>
          You may use the rescue group's or shelter's adoption physical exam if you have
          documentation of the examination, including the doctor's notes. If you are unsure
          if the adoption physical satisfies the policy requirement, you may fax us a copy of
          the records to review. We will inform you if this exam satisfies the policy
          requirements or if another examination is required.
        </p>
        <p>
           Please note that many adoption agreements recommend that your new furry
           family member gets a complete physical examination from your veterinarian, typically within 72 hours. This exam would satisfy the policy requirements for an
           enrollment clinical exam. 
        </p>
        <p>
        <strong>
          If you cannot get a copy of your pet’s records from the adoption facility, it
          may delay the processing of your claim and possibly affect your coverage.
        </strong>
        </p>
      </div>
    );
  };
}
